/* index.css or global CSS file */
.loader {
    border-top-color: #3498db; /* Customize spinner color */
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}